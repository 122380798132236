import $ from 'jquery';
import L from 'leaflet';
import 'leaflet.markercluster';
import QrScanner from 'qr-scanner';
import QrScannerWorkerPath from '!!file-loader!../node_modules/qr-scanner/qr-scanner-worker.min.js';
QrScanner.WORKER_PATH = '/static/' + QrScannerWorkerPath;
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'select2/dist/js/select2.full.min';

let map, circle;

const createMap = (options={}) => {
  const map = L.map(document.getElementById('map'), {
    attributionControl: false,
    ...options
  });
  L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors',
  }).addTo(map);

  const onLocationFound = (e) => {
    if (circle) {
      circle.remove()
    }
    circle = L.circle(e.latlng, e.accuracy).addTo(map);
  }
  map.on('locationfound', onLocationFound);

  return map
}

const findAddress = (latitude, longitude) => {
  return fetch(`/api/geocoding/reverse/?latitude=${latitude}&longitude=${longitude}`)
  .then((response) => response.json())
  .then((data) => ({
    latitude,
    longitude,
    ...data,
    data: JSON.stringify(data),
  }))
}

const setAddress = (address) => {
  const parts = [
    address.city,
    address.address,
  ]

  $('#address').html(parts.filter(part => !!part).join(', ') || address.region || '<em>Geen adres kunnen vinden</em>')
  $('button[disabled]').removeAttr('disabled');
  const names = ['address', 'city', 'country', 'latitude', 'longitude'];
  names.forEach((name) => {
    const input = document.getElementById(`id_add-${name}`)
    input.value = address[name] || '';
  })
}

const openFullscreen = (elem) => {
  if (elem.requestFullscreen) {
    elem.requestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
  } else if (elem.mozRequestFullScreen) { /* Firefox */
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
    elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
  } else if (elem.msRequestFullscreen) { /* IE/Edge */
    elem.msRequestFullscreen();
  }
}

const hideFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  }
}

const fullscreen = (elem) => {
  const isInFullScreen = (document.fullscreenElement && document.fullscreenElement !== null) ||
      (document.webkitFullscreenElement && document.webkitFullscreenElement !== null) ||
      (document.mozFullScreenElement && document.mozFullScreenElement !== null) ||
      (document.msFullscreenElement && document.msFullscreenElement !== null);

  if (isInFullScreen) {
    hideFullscreen()
  } else {
    openFullscreen(elem)
  }

  return !isInFullScreen
}

const icon = new L.Icon.Default();
icon.options.shadowSize = [0,0];

$('select').each((index, node) => {
  var select = $(node)
  var options = {
    tags: true,
    theme: 'bootstrap',
    width: '100%',
    allowClear: true,
    createTag: ({ term }) => {
      return {
        id: term,
        text: term,
        newOption: true
      }
    },
    ...(select.data('select2-options') || {})
  }
  select.select2(options)
})

$('.locate').on('click', () => map.locate({ setView: true }))

window.onloads = {
  HOME: () => {
    map = createMap({
      minZoom: 1,
      zoom: 7,
      center: [52.150344, 5.613529]
    })

    const scanner = $('.qr-scanner')
    let qrScanner
    $('.camera').on('click', function(e) {
      scanner.addClass('show');
      qrScanner = new QrScanner(scanner.find('video').get(0), (result) => {
        window.location.href = result
        qrScanner.destroy();
      });
      qrScanner.start()
    })

    $('.qr-scanner .close').on('click', function() {
      scanner.removeClass('show');
      qrScanner.destroy();
    })

    const markers = L.markerClusterGroup({
      chunkedLoading: true
    });
    cities.forEach(({ city, num, latitude, longitude }) => {
      const marker = L.marker([latitude, longitude], { icon, title: city });
      marker.bindPopup(`<div class="text-center">${city}: <strong class="text-primary">${num}</strong> keer doorgegeven<div</div>`)
      markers.addLayer(marker)
    })
    map.addLayer(markers);
  },
  GIFT: () => {
    map = createMap({
      maxZoom: 12,
    })
    let lastPoint, lines = [];

    points.forEach((point, index) => {
      if (!point.latitude || !point.longitude) {
        return;
      }
      lastPoint = [point.latitude, point.longitude]
      lines.push(lastPoint)
      const marker = L.marker(lastPoint, { icon }).addTo(map);
      marker.bindPopup(`<div>${point.date}</div><div>${point.event}</div><div class="text-muted">${point.city ? `${point.city}, ` : ''}${point.country}</div><em class="text-muted">${point.comment}</em>`)
    })

    const polyline = L.polyline(lines, {color: 'rgba(108, 117, 125, .5)', weight: 2}).addTo(map)
    if (lines.length) {
      map.fitBounds(polyline.getBounds());
    }

    $('.fullscreen').on('click', function() {
      const inFullscreen = fullscreen(map._container);
      const icon = inFullscreen ? 'compress-wide' : 'expand-wide';
      setTimeout(() => $(this).find('i').attr('class', `far fa-${icon}`), 500);
    })
  },
  ADD: () => {
    const handleLatLng = (latlng, accuracy) => {
      findAddress(latlng.lat, latlng.lng)
      .then(setAddress)
    }

    const addressList = $('#address-list')
    const checkIfChanged = function(q) {
      if (q != query) {
        return
      }

      fetch(`/api/geocoding/?q=${query}`)
      .then((response) => response.json())
      .then((places) => {
        const items = places.map(function(place, index) {
          const item = $('<a href="#" class="list-group-item list-group-item-action"></a>');
          item.html(place.address)
          item.on('click', function(e) {
            $('#lookup-address').modal('hide');
            handleLatLng(new L.LatLng(place.lat, place.lng), 10)
          })
          return item
        })
        addressList.html('').append(items)
      })
    }

    let query;
    $('#address-query').on('keyup', function(e) {
      if (e.target.value == query) {
        return;
      }
      query = e.target.value
      setTimeout(checkIfChanged, 300, query)
    })

    const onLocationFound = (e) => handleLatLng(e.latlng, e.accuracy)
    const onLocationError = (e) => {
      console.error(e)
      fetch('/api/geocoding/ip/')
      .then((response) => response.json())
      .then(({ lat, lon }) => {
        const latlng = new L.LatLng(lat, lon)
        map.panTo(latlng)
        handleLatLng(latlng, 1000)
      })
    }

    map.on('locationfound', onLocationFound);
    map.on('locationerror', onLocationError);
    map.locate({ setView: points.length == 0, watch: true });
  },
}

